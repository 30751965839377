<template>
  <div id="toolBar" class="mt-3 mb-3">
    <div class="d-flex">
      <!-- search Input -->
      <CommonSearchInput
        v-if="isShowSearchInput"
        @searchFn="searchFn"
        @keyup.enter="searchSubmit"
      />

      <!-- DatePicker -->
      <CommonDatePicker
        v-if="isShowDatePicker"
        :default-start-time="startTime"
        :default-end-time="endTime"
        @searchDate="searchDate"
      />

      <!-- search more Btn 進階篩選 -->
      <CommonButton
        :name="t('common.moreSearch')"
        prepend-icon="fa-solid fa-filter"
        variant="tonal"
        class="me-2"
        style="height:40px;"
        @click.stop="showSearchBlock()"
      />

      <!-- search Btn 搜尋 -->
      <CommonButton
        :name="t('common.search')"
        variant="outlined"
        style="height:41px;"
        @keyup.enter="searchSubmit()"
        @click="searchSubmit()"
      />

      <!-- clear Btn 清除篩選 -->
      <CommonButton
        v-if="isShowClearBtn"
        :name="t('common.clearSearch')"
        prepend-icon="fa-regular fa-circle-xmark"
        variant="flat"
        color="#EDF7FF"
        class="ms-3 clearBtn"
        @click.stop="clearSearch()"
      />
    </div>
  </div>
</template>

<script setup>
const { t } = useI18n()

const props = defineProps({
  isShowSearchInput: {
    type: Boolean,
    default: false
  },
  isShowDatePicker: {
    type: Boolean,
    default: false
  },
  startTime: {
    type: Number,
    default: 0
  },
  endTime: {
    type: Number,
    default: 0
  },
  isShowClearBtn: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['showSearchBlockFn', 'searchSubmitFn', 'clearSearchFn'])

const searchParams = ref({
  input: '',
  startTime: props.startTime,
  endTime: props.endTime
})

// 預設輸入框的值
const searchFn = (v) => {
  searchParams.value.input = v
}

// 日期選擇
const searchDate = (val) => {
  searchParams.value.startTime = val.startTime
  searchParams.value.endTime = val.endTime
}

// 展開進階搜尋
const showSearchBlock = () => {
  emit('showSearchBlockFn', true)
}

// 提交搜尋
const searchSubmit = () => {
  emit('searchSubmitFn', searchParams.value)
}

// 清除篩選
const clearSearch = () => {
  emit('clearSearchFn', true)
}

</script>

<style lang="scss" scoped>
#toolBar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

:deep(.el-date-editor) {
    height: 41px;
    width: 356px;
}
:deep(.el-date-editor i) {
    margin-right: 10px;
}

.clearBtn { // 清除篩選btn
  :deep(.v-btn) {
    height:40px;
    color: #2F88FF;
  }
}
</style>
