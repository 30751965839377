<!-- commonPagination.vue -->
<template>
  <el-pagination
    v-model:current-page="currentPage"
    v-model:page-size="pageSize"
    :page-sizes="pageSizeSelectValue"
    size="default"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    @size-change="handleSizeChangeFn"
    @current-change="handleCurrentChangeFn"
  />
</template>

<script setup>

const props = defineProps({
  pageSizeSelectValue: {
    type: Array,
    default: () => [30, 50, 100]
  },
  total: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(['handleSizeChange', 'handleCurrentChange'])

const currentPage = ref(1)
const pageSize = ref(props.pageSizeSelectValue[0] || 30)

// 切換每頁顯示條數
const handleSizeChangeFn = (val) => {
  emit('handleSizeChange', val)
}

// 切換頁數
const handleCurrentChangeFn = (val) => {
  emit('handleCurrentChange', val)
}
</script>

<style lang="scss">
</style>
